import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Col, DatePicker, Row, Typography } from 'antd';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import lineChart from './configs/lineChart';
import backendApiLink from '../../backendLinkConfig';

function LineChartHeatOven() {
    const { Title } = Typography;
    const [loading, setLoading] = useState(false);
    const [temperatureData, setTemperatureData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        getDataForChart();
    }, []);

    const getDataForChart = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${backendApiLink}/getTemperatureDataBetweenDates`, {
                startDate,
                endDate,
            });
            if (response.data.success) {
                const tempData = response.data.result;
                setTemperatureData(tempData.map(item => item.TEMP));
                setChartCategories(tempData.map(item => moment(item.date).format('YYYY-MM-DD' + ' ' + 'HH:mm')));
            } else {
                console.log('Error:', response.data.message);
            }
        } catch (error) {
            console.log('Error:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (dates, dateStrings) => {
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
    };

    const lineChartData = {
        series: [{
            name: "Temperature",
            data: temperatureData
        }],
        options: {
            ...lineChart.options,
            xaxis: {
                categories: chartCategories,
            },
        },
    };

    return (
        <>
            <div className="linechart">
                <Title level={5}>Temperature Variation Details</Title>
                <div className="sales">
                    <ul>
                        <li>
                            <MinusOutlined style={{ color: "#008ffb", marginTop: "40px" }} />
                            Temperature °C
                        </li>
                    </ul>
                </div>
            </div>
            <Row gutter={16}>
                <Col span={18}>
                    <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        disabledDate={d => !d || d.isAfter(moment())}
                        onChange={handleDateChange}
                    />
                </Col>
                <Col span={6}>
                    <Button type="primary" onClick={getDataForChart} style={{ width: '100%' }}>
                        Get Data
                    </Button>
                </Col>
            </Row>
            <ReactApexChart
                className="full-width"
                options={lineChartData.options}
                series={lineChartData.series}
                type="line"
                height={500}
                width={"100%"}
            />
        </>
    );
}

export default LineChartHeatOven;
