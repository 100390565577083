import React, { Component } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Progress,
    InputNumber,
    DatePicker,
    message,
    Modal,
    Select,
    Popconfirm
} from 'antd';
import axios from 'axios';
import backendApiLink from "../../backendLinkConfig";
import LineChart from "../../components/chart/LineChart";
import LineChartHeatOven from "../../components/chart/LineChartHeatOven";
import moment from "moment";

class HeatTreatmentOven extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            currentTemperature: 0,
            updatedTime: '2021-09-01 12:00:00',
            setTemperature: 0,
            machineStatus: 'OFF',
            setTemperatureOtherMode:false,
            dateRange: [],
            numberOfPoints: 100,
            isViewModalVisible: false,
            alarmStatus: 0,
        };

        this.getLastTemperature = this.getLastTemperature.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDataForChart = this.getDataForChart.bind(this);
        this.getLastTemperature1 = this.getLastTemperature1.bind(this);

    }

    componentDidMount() {
        this.getLastTemperature1(); // Initial call on component mount
        this.interval = setInterval(this.getLastTemperature, 1500);
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Clear the interval to prevent memory leaks
    }

    stopOven1 = async () => {
        try {
            const response = await axios.post(`${backendApiLink}/stopOven1`);
            if (response.data.success) {
                message.success('Oven stopped successfully');
            } else {
                message.error('Failed to stop oven');
            }
        } catch (error) {
            console.error('Error stopping oven:', error);
            message.error('Failed to stop oven');
        }
    }

    stopAlarm1 = async () => {
        try {
            const response = await axios.post(`${backendApiLink}/stopAlarm1`);
            if (response.data.success) {
                message.success('Alarm stopped successfully');
                this.setState({ alarmStatus: 0 });
            } else {
                message.error('Failed to stop alarm');
            }
        } catch (error) {
            console.error('Error stopping alarm:', error);
            message.error('Failed to stop alarm');
        }
    }

    async getLastTemperature() {
        this.setState({ loading: true });

        try {
            const response = await axios.post(`${backendApiLink}/getLastTemperature`);
            // const response = await axios.post('https://nihalgems.online/api/getLastTemperature');

            if (response.data.success) {
                // console.log('Response:', response.data);
                const { result } = response.data;

                const { TEMP } = result;
                // const updatedTime1 = new Date(result.CREATED_DATE).toISOString().replace('T', ' ').slice(0, 19);
                const updatedTime1 = result.CREATED_DATE ? new Date(result.CREATED_DATE).toISOString().replace('T', ' ').slice(0, 19) : moment().format('YYYY-MM-DD HH:mm:ss');

                this.setState({
                    currentTemperature: TEMP,
                    updatedTime: updatedTime1,
                    setTemperature: result.SET_VALUE,
                    machineStatus: response.data.machineStatus,
                    alarmStatus: result.ALARM ? parseInt(result.ALARM) : 0,
                });

            } else {
                console.log('Error:', response.data.message);
            }
        } catch (error) {
            console.log('Error:', error.message);
        } finally {
            this.setState({ loading: false });
        }
    }

    async getLastTemperature1() {
        this.setState({ loading: true });

        try {
            const response = await axios.post(`${backendApiLink}/getLastTemperature`);
            // const response = await axios.post('https://nihalgems.online/api/getLastTemperature');

            if (response.data.success) {
                //console.log('Response:', response.data);
                const { result } = response.data;
                const { TEMP } = result;
                const updatedTime1 = result.CREATED_DATE ? new Date(result.CREATED_DATE).toISOString().replace('T', ' ').slice(0, 19) : moment().format('YYYY-MM-DD HH:mm:ss');

                this.setState({
                    currentTemperature: TEMP,
                    updatedTime: updatedTime1,
                    setTemperature: result.SET_VALUE,
                    machineStatus: response.data.machineStatus,
                    alarmStatus: result.ALARM ? parseInt(result.ALARM) : 0,
                });

                this.formRef.current.setFieldsValue({
                    SetValue1: result.SET_VALUE1,
                    SetTime1: result.SET_TIME1,
                    SetValue2: result.SET_VALUE2,
                    SetTime2: result.SET_TIME2,
                    SetValue3: result.SET_VALUE3,
                    SetTime3: result.SET_TIME3,
                    SetValue4: result.SET_VALUE4,
                    SetTime4: result.SET_TIME4,
                    SetValue5: result.SET_VALUE5,
                    SetTime5: result.SET_TIME5,
                    SetValue6: result.SET_VALUE6,
                    SetTime6: result.SET_TIME6,
                });

            } else {
                console.log('Error:', response.data.message);
            }
        } catch (error) {
            console.log('Error:', error.message);
        } finally {
            this.setState({ loading: false });
        }
    }

    async handleSubmit(values) {
        //console.log('Form values:', values);
        const setValues = [values.SetValue1, values.SetValue2, values.SetValue3, values.SetValue4, values.SetValue5, values.SetValue6];
        const setTimes = [values.SetTime1, values.SetTime2, values.SetTime3, values.SetTime4, values.SetTime5, values.SetTime6];

        try {
            const response = await axios.post(`${backendApiLink}/changeSetTemperature`, { setValues, setTimes }, {
                timeout: 5000 // timeout of 5 seconds
            });
            //console.log('Response:', response);
            if (response.data && response.data.success) {
                message.success('Set temperature updated successfully');
            } else {
                message.error('Failed to update set temperature');
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                message.error('Request timeout: Failed to update set temperature within 5 seconds');
            } else {
                message.error('Failed to update set temperature');
            }
            console.error('Error updating set temperature:', error);
        }
    }





    async getDataForChart() {
        this.setState({ isViewModalVisible: true })
    }

    render() {
        const { currentTemperature, updatedTime,setTemperature } = this.state;
        const progressPercent = (currentTemperature / 1900) * 100;
        const progressStatus = currentTemperature > 1000 ? 'exception' : 'normal'; // Change color if over 1000

        const progressPercentSet = (setTemperature / 1900) * 100;
        const progressStatusSet = setTemperature > 1000 ? 'exception' : 'normal'; // Change color if over 1000

        return (
            <>
                {/* Cards and Tables */}
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="center">
                        <Col xs={24} xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                style={{ padding: '20px' }}
                                title={
                                    <div>
                                        {this.state.machineStatus === 'OFF'
                                            ? <span>🔴 Oven 1 <br />(OFF or DISCONNECTED)</span>
                                            : <span>🟢 Oven 1 <br />(ON) {this.state.alarmStatus === 1 ? <span style={{ color: 'red' }}>ALARM ON</span> : null}</span>
                                        }
                                    </div>
                                }
                            >

                            <div className="table-responsive">
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} xl={6}>
                                            <Card
                                                bordered={false}
                                                title='Process Status'
                                                align="center"
                                                className="criclebox tablespace mb-24"
                                            >
                                                <Row gutter={[16, 16]} justify="center">
                                                    {this.state.alarmStatus === 1 &&
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Button type="default" danger onClick={this.stopAlarm1} size={'large'} disabled={this.state.machineStatus === 'OFF'} style={{ width: '100%' }}>
                                                            Stop Alarm
                                                        </Button>
                                                    </Col>
                                                    }
                                                </Row>
                                                <div className="progress-container">
                                                    <h3>Current Temperature</h3> {/* Title for the first progress circle */}
                                                    <Progress
                                                        type="circle"
                                                        percent={progressPercent}
                                                        status={progressStatus}
                                                        style={{ width: '100%'}}
                                                        format={percent => `${((percent / 100) * 1900).toFixed(1)}°C`}
                                                    />
                                                </div>
                                                <div className="progress-container">
                                                    <h3>Working Set Value</h3> {/* Title for the second progress circle */}
                                                    <Progress
                                                        type="circle"
                                                        percent={progressPercentSet}
                                                        status={progressStatusSet}
                                                        style={{ width: '100%'}}
                                                        format={percent => `${((percent / 100) * 1900).toFixed(1)}°C`}
                                                    />
                                                </div>
                                                <p>Last Updated: {moment(updatedTime).add(5, 'hours').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}</p>
                                                {/*{this.state.machineStatus === 'OFF' && <p style={{ color: 'red' }}>Machine Can be OFF Because of inactivity of more than 3 hours</p>}*/}
                                                <Row gutter={[16, 16]} justify="center">
                                                    {this.state.machineStatus === 'ON' &&
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Button type="primary" danger onClick={this.stopOven1} size={'large'}  disabled={this.state.machineStatus === 'OFF'} style={{ width: '100%' }}>
                                                            Stop Machine
                                                        </Button>
                                                    </Col>
                                                    }
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Button type="primary" onClick={this.getLastTemperature1} size={'large'} disabled={this.state.machineStatus === 'OFF'} style={{ width: '100%' }}>
                                                            Refresh
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card>

                                        </Col>
                                        <Col xs={24} xl={6}>
                                            <Row gutter={[16, 16]} justify="center">
                                            <Card
                                                className="criclebox tablespace mb-24"
                                                title='Set Temperature'
                                                align="center"
                                            >
                                                <Form
                                                    layout="vertical"
                                                    style={{ margin: '20px' }}
                                                    ref={this.formRef}
                                                    onFinish={this.handleSubmit}
                                                >
                                                    {/*{this.state.setTemperatureOtherMode === false &&*/}
                                                    {/*<Form.Item*/}
                                                    {/*    name="SetTemperature"*/}
                                                    {/*    rules={[{ required: true, message: 'Please input temperature!' }]}*/}
                                                    {/*    initialValue={100}*/}
                                                    {/*>*/}
                                                    {/*    <InputNumber*/}
                                                    {/*        min={1} // Set the minimum value (adjust as needed)*/}
                                                    {/*        max={1900} // Set the maximum value to 1900*/}
                                                    {/*        placeholder="Temperature"*/}
                                                    {/*        style={{ width: '100%' }} // Make sure the input fits the form item width*/}
                                                    {/*        onChange={value => {this.setState({ setTemperature: value })}}*/}
                                                    {/*    />*/}
                                                    {/*</Form.Item>*/}
                                                    {/*}*/}
                                                    <Row gutter={[16, 16]} justify="center">
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue1"
                                                                label={'Set Value 1'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 1"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime1"
                                                                label={'Time 1'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 1"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue2"
                                                                label={'Set Value 2'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 2"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime2"
                                                                label={'Time 2'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 2"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue3"
                                                                label={'Set Value 3'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 3"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime3"
                                                                label={'Time 3'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 3"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue4"
                                                                label={'Set Value 4'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 4"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime4"
                                                                label={'Time 4'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 4"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue5"
                                                                label={'Set Value 5'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 5"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime5"
                                                                label={'Time 5'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 5"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetValue6"
                                                                label={'Set Value 6'}
                                                                rules={[{ required: true, message: 'Please input temperature!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1900} // Set the maximum value to 1900
                                                                    placeholder="Set Value 6"
                                                                    style={{ width: '100%' }} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} xl={12}>
                                                            <Form.Item
                                                                name="SetTime6"
                                                                label={'Time 6'}
                                                                rules={[{ required: true, message: 'Please input time!' }]}
                                                            >
                                                                <InputNumber
                                                                    min={1} // Set the minimum value (adjust as needed)
                                                                    max={1000} // Set the maximum value to 1000
                                                                    placeholder="Time 6"
                                                                    style={{ width: '95%' , marginLeft: '10px'}} // Make sure the input fits the form item width
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    {this.state.machineStatus === 'ON' &&
                                                        <Button type="primary" htmlType="submit" className="login-form-button">
                                                        Set
                                                    </Button>
                                                    }
                                                </Form>

                                            </Card>
                                            </Row>

                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Card style={{ padding: '20px' }}
                                                className="criclebox tablespace mb-24">
                                                <LineChart />
                                                <Button type="default" onClick={this.getDataForChart} style={{ width: '100%' }}>
                                                    View Detailed Chart
                                                </Button>

                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        visible={this.state.isViewModalVisible}
                        onCancel={() => this.setState({ isViewModalVisible: false })}
                        footer={null}
                        width={1400}
                    >
                        <LineChartHeatOven/>
                    </Modal>
                </div>
            </>
        );
    }
}

export default HeatTreatmentOven;
