import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import axios from "axios";
import { useEffect, useState } from "react";
import backendApiLink from "../../backendLinkConfig";

function LineChart() {
    const { Title, Paragraph } = Typography;
    const [loading, setLoading] = useState(false);
    const [temperatureData, setTemperatureData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${backendApiLink}/getTemperatureDataLast30Days`);
            if (response.data.success) {
                const tempData = response.data.result;
                console.log("Temperature Data:", tempData);
                setTemperatureData(tempData.map((item) => item.TEMP));
                setChartCategories(tempData.map((item) => item.date));
            } else {
                console.log("Error:", response.data.message);
            }
        } catch (error) {
            console.log("Error:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const lineChartData = {
        series: [{
            name: "Temperature",
            data: temperatureData,
            offsetY: 0,
        }],
        options: {
            ...lineChart.options,
            xaxis: {
                categories: chartCategories,
            },
        },
    };

    return (
        <>
            <div className="linechart">
                <div>
                    <Title level={5}>History</Title>
                    <Paragraph className="lastweek">
                        <span className="bnb2"><CheckOutlined /></span> Temperature Variation of last 30 days.
                    </Paragraph>
                </div>
                <div className="sales">
                    <ul>
                        <li>
                            {<MinusOutlined style={{ color: "#008ffb" }} />}Temperature °C
                        </li>
                    </ul>
                </div>
            </div>

            <ReactApexChart
                className="full-width"
                options={lineChartData.options}
                series={lineChartData.series}
                type="area"
                height={250}
                width={"100%"}
            />
        </>
    );
}

export default LineChart;
